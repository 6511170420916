// CardsContainer.component.jsx
import React from 'react';
import HorizontalList from '../Horizontal-list/HorizontalList.component';
import './CardContainer.style.scss';

const CardContainer = () => {
  const libraryCardsData = [
    { 
      icon: '📚', 
      title: 'Library Catalogue', 
      description: "Explore the collection of books, journals, and resources available in the library.", 
      link: "https://opac.juniv.edu" 
    },
    { 
      icon: '🏛️', 
      title: 'Institutional Repository', 
      description: "Access academic publications, research papers, and scholarly work from the institution.", 
      link: "https://repository.juniv.edu" 
    },
    { 
      icon: '📖', 
      title: 'JU Journals', 
      description: "Read and download journals published by Jahangirnagar University.", 
      link: "https://journals.juniv.edu" 
    },
    { 
      icon: '💳', 
      title: 'Digital ID Card', 
      description: "Manage your digital identity card for seamless library access.", 
      link: "https://devpis.juniv.edu/" 
    },
    { 
      icon: '🌐', 
      title: 'Remote Access', 
      description: "Access library resources remotely from anywhere in the world.", 
      link: "https://my.openathens.net/?passiveLogin=false" 
    },
    { 
      icon: '🔍', 
      title: 'Plagiarism Detection Software', 
      description: "Ensure academic integrity with our plagiarism detection tools.", 
      link: "https://jahangirnagaruniversity.turnitin.com/" 
    },
    { 
      icon: '📑', 
      title: 'Thesis List', 
      description: "Browse the list of theses and dissertations submitted by students.", 
      link: "https://library.juniv.edu/thesis_list" 
    },
    { 
      icon: '📇', 
      title: 'Catalogue Card', 
      description: "Extract data from library card images efficiently.", 
      link: "https://catalogue-card.vercel.app/" 
    }
  ];
  

  return (
    <div className="cards-container">
      {libraryCardsData.map((card, index) => (
        <HorizontalList key={index} icon={card.icon} title={card.title} description={card.description} link={card.link} />
      ))}
    </div>
  );
};

export default CardContainer;
