import React from 'react';
import IMAGES from '../imagesVariable/imagesVariable';
import './news.style.scss';

const News = () => {
  return (
    <div className="news-section">
      <h2 className="news-title">Latest News</h2>
      <div className="news-container">
        
      <div className="news-card">
      <div className="news-image-container">
        <img
          src={IMAGES.iThenticate}
          alt="Intellectual Freedom"
          className="news-image"
        />
      </div>
      <div className="news-content">
        <h3>iThenticate সফটওয়্যারটি আপগ্রেড হয়েছে |   
        </h3>
        <a href='https://jahangirnagaruniversity.turnitin.com/'>Click to Login</a>
        {/* <p>
        সকলের অবগতির জন্য জানানো যাচ্ছে যে,  iThenticate সফটওয়্যারটি আপগ্রেড করে iThenticate Version 2-এ উন্নীত করার কারণে  উক্ত সফটওয়্যারটি আগামী ০১ ফেব্রুয়ারী থেকে ০৩ ফেব্রুয়ারী পর্যম্ত  সাময়িকভাবে বন্ধ থাকবে। সাময়িক এই অসুবিধার জন্য আমরা আন্তরিকভাবে দুঃখিত।
        </p> */}
      </div>
    </div>

        <div className="news-card">
          <div className="news-image-container">
            <img
              src={IMAGES.institutional_repository}
              alt="JU Institutional Repository"
              className="news-image"
            />
          </div>
          <div className="news-content">
            <h3>JU Institutional Repository</h3>
            <p>
              We've completed our investigation and issued a final public notification regarding exposed data.
            </p>
          </div>
        </div>

        <div className="news-card">
          <div className="news-image-container">
            <img
              src={IMAGES.appLogo}
              alt="JU Library App"
              className="news-image"
            />
          </div>
          <div className="news-content">
            <h3>JU Library App: Oct 29, 2024</h3>
            <p>
            Bangladesh's first dedicated library app, offering easy access to Jahangirnagar University's library resources anytime, anywhere.</p>
          </div>
        </div>
      </div>
      <a href="/notices" className="see-all-button">
        See All News
      </a>
    </div>
  );
};

export default News;
